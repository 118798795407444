@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background: #FB904D; 
  color: white;        
}

::-moz-selection {
  background: #FB904D; 
  color: white;
}

.DirectMessage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.3s;
}

.DirectMessage.closed {
  opacity: 0;
  transform: translateY(-100%);
}

.Links {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.3s ease-in-out;
}

.Links.closed {
  opacity: 0;
  transform: translateY(-125%);
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: #adadad;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 4px;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: 140%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.underline-center-outward {
  position: relative;
  display: inline-block;
}

.underline-center-outward::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 50%;
  background-color: currentColor;
  transition:
    width 0.3s ease,
    left 0.3s ease;
}

.underline-center-outward:hover::after {
  width: 100%;
  left: 0;
}

.underline-center-outward-bold {
  position: relative;
  display: inline-block;
}

.underline-center-outward-bold::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1.5px;
  bottom: -1.5px;
  left: 50%;
  background-color: currentColor;
  transition:
    width 0.3s ease,
    left 0.3s ease;
}

.underline-center-outward-bold:hover::after {
  width: 100%;
  left: 0;
}